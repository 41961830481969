import React from 'react'
import Head from 'next/head'

function SEO({
  description = 'NO MORE boring coding videos, courses and articles. Learn code in more fun, fast and effective way by playing coding games!',
  title = 'Learn CSS, HTML and JavaScript by Playing Coding Games',
  og = '/images/seo/main-og-big.jpg',
  children = null,
  noindex = false,
}) {
  return (
    <Head>
      <title>{title}</title>
      <meta name="theme-color" content="#111111"></meta>
      <meta charSet="utf-8"></meta>
      <meta name="description" content={description}></meta>
      <meta name="og:title" content={title}></meta>
      <meta name="og:description" content={description}></meta>
      <meta name="og:type" content="website"></meta>
      <meta name="twitter:card" content="summary_large_image"></meta>
      <meta name="twitter:creator" content="@codingfantasy"></meta>
      <meta name="twitter:title" content={title}></meta>
      <meta name="twitter:description" content={description}></meta>
      <meta name="twitter:image" content={`https://codingfantasy.com${og}?4362984378`}></meta>
      <meta name="og:image" content={`https://codingfantasy.com${og}?4362984378`}></meta>
      <meta name="viewport" content="initial-scale=1.0, width=device-width"></meta>
      <link rel="icon" href={`/icon.png`} type="image/png" sizes="32x32"></link>
      <link rel="icon" href={`/favicon.ico`} type="image/x-icon"></link>
      {noindex && <meta name="robots" content="noindex"></meta>}
      {children}
    </Head>
  )
}

export default SEO
