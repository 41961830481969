import React, { useLayoutEffect, useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import Script from 'next/script'

import Header from 'components/Header'
import Footer from 'components/Footer'
import Seo from 'components/Seo'
import Button from 'components/Button'

import highlight from 'highlight.js'
import CodeHighlight from 'components/games/CodeHighlight'
import { useEffect } from 'react'
import cx from 'classnames'
import Head from 'next/head'

const signatureImg = '/images/signature-black.png'
const goalImg = '/images/hero/apple.png'
const heroImg = '/images/hero/knight.png'
const flexboxdemoImg = '/images/hero/flexboxdemo.png'
const boringImg = '/images/hero/boring.png'
const gridAttackImg = '/images/hero/grid-attack.png'
const flexboxImg = '/images/hero/flexbox.png'
const funVideo = '/videos/fun.mp4'
const csVsYoutubeVideo = '/videos/cfvsyoutube.mp4'

import s from '../styles/index.module.css'

function runConfetti() {
  //-----------Var Inits--------------
  let canvas = document.getElementById('canvas')
  let ctx = canvas.getContext('2d')
  canvas.width = window.innerWidth
  canvas.height = window.innerHeight
  let cx = ctx.canvas.width / 2
  let cy = ctx.canvas.height / 2

  let confetti = []
  const confettiCount = 200
  const gravity = 0.9
  const terminalVelocity = 10
  const drag = 0.075
  const colors = [
    { front: 'red', back: 'darkred' },
    { front: 'green', back: 'darkgreen' },
    { front: 'blue', back: 'darkblue' },
    { front: 'yellow', back: 'darkyellow' },
    { front: 'orange', back: 'darkorange' },
    { front: 'pink', back: 'darkpink' },
    { front: 'purple', back: 'darkpurple' },
    { front: 'turquoise', back: 'darkturquoise' },
  ]

  //-----------Functions--------------
  let resizeCanvas = () => {
    canvas.width = window.innerWidth
    canvas.height = window.innerHeight
    cx = ctx.canvas.width / 2
    cy = ctx.canvas.height / 2
  }

  let randomRange = (min, max) => Math.random() * (max - min) + min

  let initConfetti = () => {
    for (let i = 0; i < confettiCount; i++) {
      confetti.push({
        color: colors[Math.floor(randomRange(0, colors.length))],
        dimensions: {
          x: randomRange(10, 20),
          y: randomRange(10, 30),
        },
        position: {
          x: randomRange(0, canvas.width),
          y: canvas.height - 1,
        },
        rotation: randomRange(0, 2 * Math.PI),
        scale: {
          x: 1,
          y: 1,
        },
        velocity: {
          x: randomRange(-25, 25),
          y: randomRange(0, -50),
        },
      })
    }
  }

  //---------Render-----------
  let render = () => {
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    confetti.forEach((confetto, index) => {
      let width = confetto.dimensions.x * confetto.scale.x
      let height = confetto.dimensions.y * confetto.scale.y

      // Move canvas to position and rotate
      ctx.translate(confetto.position.x, confetto.position.y)
      ctx.rotate(confetto.rotation)

      // Apply forces to velocity
      confetto.velocity.x -= confetto.velocity.x * drag
      confetto.velocity.y = Math.min(confetto.velocity.y + gravity, terminalVelocity)
      confetto.velocity.x += Math.random() > 0.5 ? Math.random() : -Math.random()

      // Set position
      confetto.position.x += confetto.velocity.x
      confetto.position.y += confetto.velocity.y

      // Delete confetti when out of frame
      if (confetto.position.y >= canvas.height) confetti.splice(index, 1)

      // Loop confetto x position
      if (confetto.position.x > canvas.width) confetto.position.x = 0
      if (confetto.position.x < 0) confetto.position.x = canvas.width

      // Spin confetto by scaling y
      confetto.scale.y = Math.cos(confetto.position.y * 0.1)
      ctx.fillStyle = confetto.scale.y > 0 ? confetto.color.front : confetto.color.back

      // Draw confetto
      ctx.fillRect(-width / 2, -height / 2, width, height)

      // Reset transform matrix
      ctx.setTransform(1, 0, 0, 1, 0, 0)
    })
    // Fire off another round of confetti
    // if (confetti.length <= 1) initConfetti()

    window.requestAnimationFrame(render)
  }

  //---------Execution--------
  canvas.style.display = 'block'

  initConfetti()
  render()

  setTimeout(() => {
    canvas.style.display = 'none'
  }, 5000)
}

function Demo() {
  const [win, setWin] = useState(false)
  const [answer, setAnswer] = useState('')
  const [editorCode, setEditorCode] = useState('')

  useEffect(() => {
    if (/align-items *: *center/.test(answer) && !win) {
      setWin(true)
      runConfetti()
    }
  }, [answer, win])

  return (
    <>
      <div className="flex lg:flex-row flex-col justify-center items-center p-2 lg:p-8 w-full">
        <div className={cx(s.code, 'relative rounded-2xl lg:mr-20 lg:mb-0 mb-6')}>
          <div className={s.codeLine}>
            {[1, 2, 3].map((_, index) => (
              <div className={s.codeLineNumber} key={index}>
                {index + 1}
              </div>
            ))}
          </div>
          <div className="flex flex-col w-full pt-3">
            <div className={s.cssCode}>
              <pre>
                <code>
                  <span>display</span>: flex;
                  <br />
                  <span>justify-content</span>: center;
                  {win && (
                    <>
                      <br />
                      <span>align-items</span>: center;
                    </>
                  )}
                </code>
              </pre>
            </div>
            {!win && (
              <textarea
                className={s.codeTextarea}
                autoCapitalize="none"
                spellCheck="false"
                onChange={(e) => {
                  setAnswer(e.target.value)
                  document.getElementById('gameField')?.setAttribute('style', e.target.value)
                }}
                placeholder="align-items: cen..."
              ></textarea>
            )}
          </div>
        </div>
        <div className={cx(`relative z-10`, s.fieldContainer)}>
          <div
            id="gameField"
            className={cx(`flex justify-center relative z-30  w-full h-full p-2`)}
          >
            <img src={heroImg} alt="hero" className="w-1/5 h-1/5 object-contain" />
          </div>
          <div
            className={`flex items-center justify-center absolute z-20 top-0 left-0 w-full h-full`}
          >
            <img src={goalImg} alt="goal" className="w-1/10 h-auto object-contain animate-pulse" />
          </div>
          <div className={s.fieldBackground} />
        </div>
      </div>
    </>
  )
}

export default function Home() {
  return (
    <>
      <Seo />
      <div className="min-h-screen h-full bg-black flex flex-col">
        <Header withUser={false} />
        <Head>
          <link
            rel="stylesheet"
            href="https://highlightjs.org/static/demo/styles/atom-one-dark.css"
          />
        </Head>
        <section className={cx(s.hero, 'relative lg:pb-48 pb-20')}>
          <canvas id="canvas" className="hidden fixed w-screen h-screen top-0 left-0 z-0"></canvas>
          <div className="relative z-10 flex flex-col flex-auto items-center justify-center w-screen py-12 px-4">
            <h1
              className={cx(
                'lg:text-7xl text-5xl font-bold text-white mb-6 text-center',
                s.highlightText
              )}
            >
              Learn Code By Playing <span className="text-yellow-500">Games</span>
            </h1>
            <h2 className="lg:text-2xl md:text-xl text-lg text-gray-200 text-center max-w-3xl m-auto mb-8 sm:mb-6">
              No more boring courses, videos and articles.
              <br className="md:block hidden" /> Learn to code in a revolutionary new, fun, and
              effective way.
            </h2>
            <div className="rounded-md flex justify-center md:justify-start mb-2">
              <Link href="/games" prefetch passHref>
                <Button
                  tag="a"
                  btnColor="bg-gradient-to-r from-red-600 to-yellow-600"
                  btnHoverColor="bg-yellow-700"
                >
                  Play & Learn Code
                </Button>
              </Link>
            </div>
            <p className="mb-20 sm:mb-12 text-gray-400 text-center md:text-lef">
              Join 158,000+ people who start playing
            </p>
            <Demo />
          </div>
        </section>
        <section className={cx('w-full bg-white lg:pt-32 pt-16 lg:pb-48 pb-20 px-4')}>
          <h1 className={cx('lg:text-7xl md:text-5xl text-4xl font-bold  mb-6 text-center')}>
            Traditional learning has <br className="md:block hidden" />
            <span className="text-red-500">TWO BIG PROBLEMS</span>
          </h1>
          <h2 className="lg:text-2xl md:text-xl text-lg text-gray-700 text-center lg:max-w-3xl max-w-lg m-auto lg:mb-24 mb-12">
            That we are trying to fix.
          </h2>
          <div className="lg:max-w-xl max-w-lg flex flex-col items-center m-auto text-center">
            <div className="mb-8 lg:mb-16">
              <p className="lg:text-4xl md:text-3xl text-2xl font-bold mb-6">
                Problem #1: <span className="text-red-500">Lack of Practice</span>
              </p>
              <p className="text-gray-700 text-left lg:text-2xl text-lg mb-4">
                <a
                  href="https://trainingindustry.com/articles/content-development/how-the-brain-learns/"
                  target="_blank"
                  rel="noreferrer"
                  className="border-b-2 border-dotted font-medium"
                >
                  According to neuroscience research, the essential thing in learning is practice.
                </a>{' '}
                The more we practice, the more effectively our brain store learned information for
                later access. However, traditional learning is the opposite of practice.
              </p>
              <p className="text-gray-700 text-left lg:text-2xl md:text-xl text-lg mb-4">
                How many times have you opened a code editor and started coding after watching
                another coding tutorial? Probably not enough. But the problem is not with you. It's
                the way you learn.
              </p>
              <p className="text-gray-700 text-left lg:text-2xl md:text-xl text-lg">
                Coding videos are designed to be consumed like TV shows. As a result, all the hours
                spent "learning" turn into hours spent “watching theory."{' '}
                <span className="font-medium">
                  And just theory without practice leads to unproductive learning. Especially in
                  learning to code.
                </span>
              </p>
            </div>
            <div className="mb-8 lg:mb-16">
              <p className="lg:text-4xl md:text-3xl text-2xl font-bold mb-6">
                Problem #2: <span className="text-red-500">Low Engagement</span>
              </p>
              <p className="text-gray-700 text-left lg:text-2xl text-lg mb-4">
                <a
                  href="https://trainingindustry.com/articles/content-development/how-the-brain-learns/"
                  target="_blank"
                  rel="noreferrer"
                  className="border-b-2 border-dotted font-medium"
                >
                  According to neuroscience research, we need to ensure that learning is engaging
                  and taps the emotional side of the brain
                </a>
                , through humor, storytelling and games. Emphasis on the rational and logical alone
                does not produce strong memories.
              </p>
              <p className="text-gray-700 text-left lg:text-2xl text-lg mb-4">
                And do you remember the last time you watched a coding tutorial or read an article
                that wasn't boring? Hard to recall at once, right? That's because most educational
                content is boring.
              </p>
              <p className="text-gray-700 text-left lg:text-2xl text-lg mb-4">
                <span className="font-medium">
                  Boredom leads to a lack of focus and procrastination, which prevents you from
                  staying engaged
                </span>{' '}
                and remembering all that you have learned.
              </p>
            </div>
            <div className="mb-8 lg:mb-12">
              <div>
                <p className="lg:text-4xl text-3xl font-bold mb-6">Solution?</p>
                <p className="text-gray-700 lg:text-2xl md:text-xl text-lg">
                  Combine tons of practice with an engaging and interactive environment where you
                  learn by doing.
                  {/* and create{' '}
                  <span className="text-yellow-500 font-bold">Coding Fantasy.</span> */}
                </p>
              </div>
            </div>
          </div>
          <div className="lg:max-w-3xl max-w-lg flex flex-col items-center m-auto ">
            <video
              autoPlay
              loop
              muted
              playsInline
              className="rounded-2xl p-4 shadow-2xl"
              style={{ aspectRatio: '1200 / 756', background: '#121311' }}
            >
              <source src={funVideo} />
            </video>
          </div>
        </section>
        <section className={cx('relative w-full lg:pt-32 pt-16 lg:pb-48 pb-20 px-4')}>
          <h1
            className={cx(
              s.highlightText,
              'lg:text-7xl md:text-5xl text-4xl font-bold mb-6 text-center text-white'
            )}
          >
            The future of learning is <span className="text-green-500">Playing</span>
          </h1>
          <h2 className="lg:text-2xl md:text-xl text-lg text-gray-400 text-center lg:max-w-3xl max-w-lg m-auto lg:mb-28 mb-14">
            According to the latest{' '}
            <a
              href="https://cognitiontoday.com/why-fun-improves-learning-mood-senses-neurons-arousal-cognition/"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              neuroscience research
            </a>
            , having fun while learning (1) avails unique cognitive resources, (2) associates reward
            and pleasure with information, (3) strengthens and broadens memory networks, and (4)
            toggles abstract thinking and focused attention.
          </h2>

          <div className="flex lg:flex-row flex-col max-w-6xl m-auto justify-center text-white lg:text-2xl md:text-xl text-lg">
            <div className="flex-1 max-w-md mx-auto lg:mb-0 mb-10">
              <div className="w-full flex justify-center mb-6">
                <img
                  src={boringImg}
                  className="w-auto lg:h-72 h-60 rounded-md"
                  style={{ aspectRatio: '1233 / 934' }}
                  loading="lazy"
                  alt="Traditional ways to learn coding"
                />
              </div>
              <p className="lg:text-4xl text-2xl font-bold lg:mb-10 mb-4 text-center text-gray-400">
                Traditional Learning
              </p>
              <p className="text-gray-400 lg:mb-8 mb-4">
                <span role="img" aria-label="traditional learning">
                  🥵
                </span>{' '}
                Feels like forcing yourself to learn.
              </p>
              <p className="text-gray-400 lg:mb-8 mb-4">
                <span role="img" aria-label="traditional learning">
                  🥵
                </span>{' '}
                Lack of practice and real-world examples.
              </p>
              <p className="text-gray-400 lg:mb-8 mb-4">
                <span role="img" aria-label="traditional learning">
                  🥵
                </span>{' '}
                Boredom, lack of focus and procrastination.
              </p>
              {/* <p className="text-gray-400">4. Articles, videos, courses.</p> */}
            </div>
            {/* <p className="lg:text-4xl text-2xl font-bold px-8 flex items-center justify-center relative lg:-right-1 right-0 lg:my-0 my-8">
              VS.
            </p> */}
            <div className="flex-1 max-w-md mx-auto">
              <div className="w-full flex justify-center mb-6">
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  className="w-auto lg:h-72 h-60 rounded-md"
                  style={{ background: '#121311' }}
                >
                  <source src={csVsYoutubeVideo} />
                </video>
              </div>
              <p className="lg:text-4xl text-2xl font-bold text-centers lg:mb-10 mb-4 text-center">
                Learning by Playing
              </p>
              <p className="text-gray-100 lg:mb-8 mb-4">
                <span role="img" aria-label="traditional learning">
                  🥳
                </span>{' '}
                Feels like playing and having fun.
              </p>
              <p className="text-gray-100 lg:mb-8 mb-4">
                <span role="img" aria-label="traditional learning">
                  🤩
                </span>{' '}
                Learning by practising with real-world examples.
              </p>
              <p className="text-gray-100 lg:mb-8 mb-4">
                <span role="img" aria-label="traditional learning">
                  😄
                </span>{' '}
                Engaging fun-to-learn environment that keeps you in focus.
              </p>
              {/* <p className="text-yellow-500">4. Coding Fantasy.</p> */}
            </div>
          </div>
        </section>
        <section className={cx('w-full lg:pt-32 pt-16 pb-12 bg-white px-4')}>
          <h1 className={cx('lg:text-7xl md:text-5xl text-4xl font-bold mb-6 text-center')}>
            <span className="text-yellow-500 block mb-6">Coding Fantasy 🤩</span>
            <span className="block mb-6">VS</span>
            <span className="text-gray-400">Articles, videos and courses</span>
          </h1>
          <h2 className="lg:text-2xl md:text-xl text-lg text-gray-700 text-center lg:max-w-3xl max-w-lg m-auto lg:mb-16 mb-12">
            Coding Fantasy is the next-generation platform for learning code by playing games that
            combines the missing parts of traditional learning: a tremendous amount of practice and
            an engaging learning environment.
          </h2>
          <div className={cx('relative h-64 sm:h-96 w-full mx-auto')}>
            <Image
              className="rounded-2xl"
              src={'/images/hero/cfvs.png'}
              layout="fill"
              quality={100}
              objectFit="contain"
              alt="Coding Fantasy vs Traditional Learning"
            />
          </div>
        </section>
        <section className={cx('w-full lg:pt-32 pt-16 pb-12 bg-white px-4')}>
          <h1 className={cx('lg:text-7xl md:text-5xl text-4xl font-bold mb-6 text-center')}>
            Our coding <span className="text-purple-500">Games</span>
          </h1>
          <h2 className="lg:text-2xl md:text-xl text-lg text-gray-700 text-center lg:max-w-3xl max-w-lg m-auto lg:mb-28 mb-12">
            We are focusing on games that cover various aspects of HTML, CSS and JavaScript. New
            games every 1-2 months.
          </h2>
          <div className="lg:max-w-6xl max-w-lg grid lg:grid-cols-2 grid-cols-1 gap-16 m-auto justify-around">
            <div className="flex flex-col items-center text-center">
              <div
                className={cx(
                  'relative h-64 sm:h-96 w-64 sm:w-96 mb-8 rounded-2xl shadow-2xl',
                  s.gameImage
                )}
              >
                <Image
                  className="rounded-2xl"
                  src={gridAttackImg}
                  layout="fill"
                  quality={100}
                  objectFit="contain"
                  alt="Grid Attack CSS Game"
                />
              </div>
              <p className="font-bold lg:text-4xl text-3xl mb-2 text-black ">Grid Attack</p>
              <p className="lg:text-2xl md:text-xl text-lg mb-6 text-gray-700 ">
                Help a brave elf Rey find and save her brother from the hands of the most powerful
                demon on Earth using the secret weapon – CSS Grid.
              </p>
              <Link href="/games" passHref>
                <Button tag="a">Play →</Button>
              </Link>
            </div>
            <div className="flex flex-col items-center text-center">
              <div
                className={cx(
                  'relative h-64 sm:h-96 w-64 sm:w-96 mb-8 rounded-2xl shadow-2xl',
                  s.gameImage
                )}
              >
                <Image
                  className="rounded-2xl"
                  src={flexboxImg}
                  layout="fill"
                  quality={100}
                  objectFit="contain"
                  alt="Grid Attack CSS Game"
                />
              </div>
              <p className="font-bold lg:text-4xl text-3xl mb-2 text-black ">Flex Box Adventure</p>
              <p className="lg:text-2xl md:text-xl text-lg mb-6 text-gray-700 ">
                Three evil brothers scammed King Arthur with Bit Coins and stole his gold. Become a
                hero and help Arthur get his revenge using your superpower – CSS Flex Box.
              </p>
              <Link href="/games" passHref>
                <Button tag="a">Play →</Button>
              </Link>
            </div>
          </div>
        </section>
        <section className={cx('w-full lg:pt-32 pt-16 lg:pb-24 pb-12 bg-white px-4')}>
          <h1 className={cx('lg:text-7xl md:text-5xl text-4xl font-bold mb-6 text-center ')}>
            What players are <span className="text-red-500">Saying 🥰</span>
          </h1>
          <div className="m-auto max-w-7xl" style={{ minHeight: '600px' }}>
            <Script
              type="text/javascript"
              async
              src="https://testimonial.to/js/iframeResizer.min.js"
              onLoad={() => {
                setTimeout(() => {
                  iFrameResize(
                    { log: false, checkOrigin: false },
                    '#testimonialto-codingfantasy-light'
                  )
                }, 0)
                setTimeout(() => {
                  iFrameResize(
                    { log: false, checkOrigin: false },
                    '#testimonialto-codingfantasy-light'
                  )
                }, 1000)
              }}
            />
            <iframe
              id="testimonialto-codingfantasy-light"
              src="https://embed.testimonial.to/w/codingfantasy?theme=light&card=base"
              frameBorder="0"
              scrolling="no"
              width="100%"
              title="testimonialto"
            ></iframe>
          </div>
        </section>
        <section className={cx('w-full lg:pt-32 pt-16 pb-12 bg-white px-4')}>
          <h2 className={cx('lg:text-7xl md:text-5xl text-4xl font-bold text-center mb-6')}>
            Our <span className={cx('text-red-500')}>Mission</span>
          </h2>
          <p className="text-gray-700 lg:text-4xl md:text-3xl text-2xl lg:mb-12 mb-6 text-center">
            Making learning to code more <span className="text-flexbox-500">efficient</span>,{' '}
            <span className="text-green-500">interesting</span> and{' '}
            <span className="text-yellow-500">fun</span>.
          </p>
          {/* <div className="max-w-xl mx-auto">
            <p className="text-gray-700 lg:text-2xl md:text-xl text-lg mb-6">
              Hi, my name is{' '}
              <a
                href="https://twitter.com/nickbulljs?ref=codingfantasy.com"
                target="_blank"
                className="border-b-2 border-dotted border-gray-700"
              >
                Nick
              </a>
              . I'm the founder of Coding Fantasy. I've been programming for over 7 years and I've
              learned to code using traditional ways.
            </p>
            <p className="text-gray-700 lg:text-2xl md:text-xl text-lg mb-6">
              I remember those days. How often I forced myself to sit and learn to code. How boring
              articles and documentation seemed to me. How much I lacked practice and because of
              this knowledge quickly disappeared.{' '}
              <span className="font-medium">
                Learning shouldn't be like that, so I decided to change it.
              </span>
            </p>
            <p className="text-gray-700 lg:text-2xl md:text-xl text-lg mb-6">
              I started with the fundamentals and studied how our brain learns. Neuroscience{' '}
              <a
                href="https://trainingindustry.com/articles/content-development/how-the-brain-learns/"
                target="_blank"
                rel="noreferrer"
                className="border-b-2 border-dotted text-gray-700 "
              >
                says
              </a>{' '}
              that{' '}
              <span className="font-medium">
                effective learning requires (1) a lot of practice and (2) a sense that learning is
                engaging
              </span>
              . Exactly what is often missed in traditional ways of learning. Exactly why I had a
              hard time learning to code. And exactly what needed to be changed.
            </p>
            <p className="text-gray-700 lg:text-2xl md:text-xl text-lg mb-6">
              I've created Coding Fantasy as a new and more efficient way to learn code. Games are
              one of the best combination of (1) practice and (2) an engaging learning environment.
              They are the next logical step of traditional education. They are the future of
              learning. And{' '}
              <span className="font-medium">
                my mission and mission of the Coding Fantasy is to make that future our reality.
              </span>
            </p>
            <p className="text-gray-700 text-lg mb-6 relative italic flex flex-col items-end">
              <div className="h-20 w-40 relative">
                <Image
                  src={signatureImg}
                  layout="fill"
                  quality={100}
                  objectFit="contain"
                  alt="Flexbox Terminology"
                />
              </div>
              <p>Nick Bull, Founder of Coding Fantasy</p>
            </p>
          </div> */}
        </section>
        <section className={cx('relative w-full lg:pt-32 pt-16 lg:pb-24 pb-12 px-4')}>
          <h1
            className={cx(
              s.highlightText,
              'lg:text-7xl md:text-5xl text-4xl font-bold mb-6 text-center text-white'
            )}
          >
            Ready to start your <span className="text-indigo-500">Adventure?</span>
          </h1>
          <h2 className="lg:text-2xl md:text-xl text-lg text-gray-400 text-center max-w-3xl m-auto mb-12">
            Join 11,000+ people who start playing
          </h2>
          <div className="flex justify-center">
            <Link href="/games" prefetch passHref>
              <Button
                tag="a"
                btnColor="bg-gradient-to-r from-red-600 to-yellow-600"
                btnHoverColor="bg-yellow-700"
                className="px-8 py-4 text-2xl"
              >
                Play & Learn Code
              </Button>
            </Link>
          </div>
        </section>
        <Footer />
      </div>
    </>
  )
}
